// import { DISTRIBUTOR, MERCHANT, SUPERVISOR } from "config/constants";
import Layout from '../components/Layout';
// import { lazy, Suspense } from "react";
import EventList from '../views/Events';
import Event from '../views/Events/Event';
import NotFound from '../views/NotFound';
import Home from '../views/Home';
import Teams from '../views/Teams';
import Account from '../views/Account';
import Login from '../views/Login';
import SignUp from '../views/Signup';
import TeamProfile from '../views/Teams/TeamProfile';
// const Home = lazy(() => import("../views/Home"));
//Icons
// const DashboardIcon = lazy(() => import('@material-ui/icons/Dashboard'));

const routes = [
   {
      path: '/',
      element: <Layout />,
      children: [
         { index: true, element: <Home /> },
         {
            path: '/events',
            element: <EventList />,
            children: [{ path: '/events/:slug', element: <Event /> }],
         },
         {
            path: '/teams',
            element: <Teams />,
         },
         {
            path: '/team/:slug',
            element: <TeamProfile />,
         },
         {
            path: '/account',
            element: <Account />,
            // children: [{ path: '/account/:slug', element: <Event /> }],
         },
         { path: '*', element: <NotFound /> },
      ],
   },
   {
      path: '/login',
      element: <Login />,
   },
   {
      path: '/signup',
      element: <SignUp />,
   },
];

export default routes /* .filter((route) => route.enabled) */;
