import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grow } from '@mui/material';
import { forwardRef } from 'react';
import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
   backgroundColor: theme.palette.error.main,
   color: theme.palette.error.contrastText,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
   marginTop: 15,
   marginBottom: -5,
}));

const Transition = forwardRef(function Transition(props, ref) {
   return <Grow direction="up" ref={ref} {...props} />;
});
export default function CustomWarningDialog({ isOpen, actions, message, title }) {
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

   return (
      <Dialog
         fullScreen={fullScreen}
         open={isOpen}
         TransitionComponent={Transition}
         aria-labelledby={title}
      >
         <StyledDialogTitle>{title}</StyledDialogTitle>
         <StyledDialogContent>
            <DialogContentText>{message}</DialogContentText>
         </StyledDialogContent>
         <DialogActions>{actions}</DialogActions>
      </Dialog>
   );
}

CustomWarningDialog.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
   actions: PropTypes.node.isRequired,
};

CustomWarningDialog.defaultProps = {
   title: 'Are you sure you want to delete?',
   message: "This action can't be undone.",
};
