import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { ORG_NAME, ORG_SITE } from '../config/constants';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { makeValidate, Checkboxes } from 'mui-rff';
import * as Yup from 'yup';
import { doSignUp } from '../apis/auth';
import { Container, InputAdornment, Link as MatLink } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <MatLink color="inherit" target="_blank" href={ORG_SITE}>
            {ORG_NAME}
         </MatLink>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   );
}
const phoneRegExp =
   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object({
   email: Yup.string().email().required(),
   password: Yup.string().required().min(6),
   firstName: Yup.string().required(),
   phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required(),
   lastName: Yup.string().required(),
   terms: Yup.boolean().oneOf([true], 'Please accept the terms').required(),
});

const validate = makeValidate(schema);
// const required = makeRequired(schema);
const initialValues = {
   firstName: '',
   lastName: '',
   email: '',
   password: '',
   terms: false,
};

export default function SignUp() {
   let navigate = useNavigate();
   const handleSignUp = async values => {
      await doSignUp({
         name: values['firstName'] + ' ' + values['lastName'],
         email: values['email'],
         phone: values['phone'],
         password: values['password'],
      })
         .then(res => {
            if (res.data.success) {
               navigate('/login');
            }
         })
         .catch(err => console.log(err));
   };

   return (
      <Container component="main" maxWidth="xs">
         <Box
            sx={{
               marginTop: 8,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
               <LockOutlinedIcon />
            </Avatar>
            <Typography sx={{ mb: 2 }} component="h1" variant="h5">
               Register to E-Warz 😎
            </Typography>
            <Form
               validate={validate}
               onSubmit={handleSignUp}
               initialValues={initialValues}
               render={({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit} noValidate={true}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              autoComplete="given-name"
                              name="firstName"
                              required
                              fullWidth
                              id="firstName"
                              label="First Name"
                              autoFocus
                           />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              required
                              fullWidth
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                              autoComplete="family-name"
                           />
                        </Grid>
                     </Grid>
                     <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        required
                        //    required={required.hidden}
                     />
                     <TextField
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        InputProps={{
                           startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                        autoComplete="tel"
                        autoFocus
                        required
                        //    required={required.hidden}
                     />
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                     />
                     <Checkboxes
                        name="terms"
                        data={{
                           label: (
                              <Typography variant="subtitle2">
                                 I certify that I am at least 18 years old and have read and agree
                                 to the{' '}
                                 <MatLink href="https://iyg.co.in/terms" target="_blank">
                                    Terms of Service
                                 </MatLink>
                                 .
                              </Typography>
                           ),
                           value: true,
                        }}
                     />
                     <Button
                        type="submit"
                        disabled={submitting}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                     >
                        Sign Up
                     </Button>
                  </form>
               )}
            />
            <Grid container justifyContent="flex-end">
               <Grid item>
                  <MatLink component={Link} to="/login" variant="body2">
                     Already have an account? Sign in
                  </MatLink>
               </Grid>
            </Grid>
         </Box>
         <Copyright sx={{ mt: 5 }} />
      </Container>
   );
}
