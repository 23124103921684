/* eslint-disable eqeqeq */
import { SESSION_TIME } from '../config/constants';
import Cookies from 'js-cookie';

export function setAuth(auth, isRemeber) {
   Cookies.set('iyg_ewarz_Authorization', auth.Authorization, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_id', auth.id, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_name', auth.name, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_phone', auth.phone, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_role', auth.role_name, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_type', auth.role_type, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_email', auth.email, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   Cookies.set('iyg_ewarz_user_pic', auth.image, {
      expires: isRemeber ? SESSION_TIME : null,
   });

   let userObject = {};
   userObject.token = auth.Authorization;
   userObject.id = auth.id;
   userObject.name = auth.name;
   userObject.phone = auth.phone;
   userObject.role = auth.role_name;
   userObject.type = auth.role_type;
   userObject.email = auth.email;
   userObject.pic = auth.image

   return userObject;
}

export function setCurrLocation(locationObj, isRemeber) {
   Cookies.set('iyg_ewarz_user_Location', locationObj, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   return locationObj;
}

export function getCurrLocation() {
   try {
      return JSON.parse(Cookies.get('iyg_ewarz_user_Location'));
   } catch (error) {
      return null;
   }
}

export function setCurrLocality(locObj, isRemeber) {
   Cookies.set('iyg_ewarz_user_Locality', locObj, {
      expires: isRemeber ? SESSION_TIME : null,
   });
   return locObj;
}

export function getCurrLocality() {
   try {
      return JSON.parse(Cookies.get('iyg_ewarz_user_Locality'));
   } catch (error) {
      return null;
   }
}

export function getAuth() {
   let userObject = {};
   userObject.token = Cookies.get('iyg_ewarz_Authorization');
   userObject.id = Cookies.get('iyg_ewarz_user_id');
   userObject.name = Cookies.get('iyg_ewarz_user_name');
   userObject.phone = Cookies.get('iyg_ewarz_user_phone');
   userObject.role = Cookies.get('iyg_ewarz_user_role');
   userObject.type = Cookies.get('iyg_ewarz_user_type');
   userObject.email = Cookies.get('iyg_ewarz_user_email');
   userObject.pic = Cookies.get('iyg_ewarz_user_pic');
   return userObject;
}

export function resetAuth() {
   Cookies.remove('iyg_ewarz_Authorization');
   Cookies.remove('iyg_ewarz_user_id');
   Cookies.remove('iyg_ewarz_user_name');
   Cookies.remove('iyg_ewarz_user_phone');
   Cookies.remove('iyg_ewarz_user_role');
   Cookies.remove('iyg_ewarz_user_type');
   Cookies.remove('iyg_ewarz_user_email');
   Cookies.remove('iyg_ewarz_user_pic');

   let userObject = {};

   return userObject;
}

export function validateAuth(auth) {
   let userObject = {};
   userObject.token = Cookies.get('iyg_ewarz_Authorization');
   userObject.id = Cookies.get('iyg_ewarz_user_id');
   userObject.name = Cookies.get('iyg_ewarz_user_name');
   userObject.phone = Cookies.get('iyg_ewarz_user_phone');
   userObject.role = Cookies.get('iyg_ewarz_user_role');
   userObject.type = Cookies.get('iyg_ewarz_user_type');
   if (
      userObject.token &&
      userObject.id &&
      userObject.name &&
      userObject.phone &&
      userObject.role &&
      userObject.type &&
      auth.token == userObject.token &&
      auth.id == userObject.id &&
      auth.name == userObject.name &&
      auth.phone == userObject.phone &&
      auth.role == userObject.role &&
      auth.type == userObject.type
   ) {
      return true;
   }

   return false;
}

export function setTheme(isLightTheme) {
   Cookies.set('theme_preference', isLightTheme, { expires: 365 });
   return isLightTheme === 'true';
}

export function getTheme() {
   const isLightTheme = Cookies.get('theme_preference');
   // console.log(isLightTheme);
   // if (isLightTheme === undefined) {
   // }
   if (isLightTheme === 'true') {
      return true;
   }
   if (isLightTheme === 'false') {
      return false;
   }
   return isLightTheme;
}
