import { Avatar, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import CustomError from '../../components/CustomError';
import { useTeamInvites } from '../../hooks/useTeamInvites';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useMutation, useQueryClient } from 'react-query';
import { respondInvite } from '../../apis/teams';
import Empty from '../../components/Empty';
const Invites = () => {
   const { data, isLoading, isError, error } = useTeamInvites();

   const queryClient = useQueryClient();

   const repinviteMut = useMutation(data => respondInvite(data), {
      onSuccess: () => {
         queryClient.invalidateQueries(['my-invites']);
      },
   });

   if (isError) {
      return <CustomError error={error} />;
   }
   return (
      <>
         {isLoading && <LinearProgress />}
         <List>
             {data.length > 0 ? 
             data.map(invite => (
                <ListItem
                   key={invite.token}
                   secondaryAction={
                      <>
                         <IconButton
                            sx={{ mr: 1 }}
                            onClick={() =>
                               repinviteMut.mutate({
                                  token: invite.token,
                                  status: 2,
                               })
                            }
                         >
                            <CloseOutlinedIcon color="error" />
                         </IconButton>
                         <IconButton
                            onClick={() =>
                               repinviteMut.mutate({
                                  token: invite.token,
                                  status: 1,
                               })
                            }
                         >
                            <CheckOutlinedIcon color="success" />
                         </IconButton>
                      </>
                   }
                >
                   <ListItemAvatar>
                      <Avatar src={invite.team_image} />
                   </ListItemAvatar>
                   <ListItemText primary={invite.team_name} secondary={invite.invite_role} />
                </ListItem>
             ))
              : <Empty message={'No invitations yet.'} />
            }
         </List>
      </>
   );
};

export default Invites;
