import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, makeValidateSync, Select, TextField } from 'mui-rff';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import { Checkbox, Grid, ListItemText, MenuItem } from '@mui/material';
import { useQueryClient } from 'react-query';
import { sendInvite, updateMemberRole } from '../../apis/teams';
import { createFilterOptions } from '@mui/material/useAutocomplete';

const addSchema = Yup.object().shape({
   email: Yup.string().email().required(),
   invite_role: Yup.string().required(),
});
const editSchema = Yup.object().shape({
   role: Yup.array().of(Yup.string()),
});

const validateAdd = makeValidateSync(addSchema);
const validateEdit = makeValidateSync(editSchema);
// const required = makeRequired(schema);

const InviteMemForm = ({ setIsOpen, mode, initVal, data }) => {
   //  console.log('re ren', setIsOpen);
   const queryClient = useQueryClient();
   // console.log('re ren', data, data.role[0]);
   const initialValues = {
      role: data?.role,
   };
   console.log(initVal);
   const handleSubmit = async values => {
      if (setIsOpen) {
         setIsOpen(false);
      }

      const { email, invite_role, role } = values;
      // console.log(values);
      if (mode === 'edit') {
         const { team_slug } = initVal;
         await updateMemberRole({
            team_user_id: data?.team_member_id,
            slug: team_slug,
            team_member_roles: role,
         })
            .then(res => {
               queryClient.invalidateQueries(['team', initVal.team_slug]);
            })
            .catch(err => console.log(err));
      } else {
         await sendInvite({
            slug: initVal.team_slug,
            email: email,
            invite_role: invite_role,
         })
            .then(res => {
               queryClient.invalidateQueries(['team', initVal.team_slug]);
            })
            .catch(err => console.log(err));
      }
   };

   const filter = createFilterOptions();

   const autocompleteData = [
      { label: 'Player', value: 'player' },
      { label: 'Captain', value: 'captain' },
      { label: 'Coach', value: 'coach' },
      { label: 'Stand In', value: 'stand_in' },
      { label: 'Manager', value: 'manager' },
   ];

   return (
      <>
         <Form
            validate={mode === 'edit' ? validateEdit : validateAdd}
            onSubmit={handleSubmit}
            validateOnBlur
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
               <form
                  style={{ marginTop: 10 }}
                  id="invitationForm"
                  onSubmit={handleSubmit}
                  noValidate={true}
                  preventDefault={true}
               >
                  <Grid container spacing={1}>
                     {mode !== 'edit' ? (
                        <>
                           <Grid mt={2} item xs={12}>
                              <Select
                                 label="Choose Role"
                                 id="invite_role"
                                 name="invite_role"
                                 autoFocus
                                 // renderValue={(selected) => selected.join(', ')}
                                 required
                              >
                                 <MenuItem value="" disabled={true}>
                                    Choose...
                                 </MenuItem>
                                 <MenuItem key={0} value={'player'}>
                                    <ListItemText>Player</ListItemText>
                                 </MenuItem>
                                 <MenuItem key={1} value={'captain'}>
                                    <ListItemText>Captain</ListItemText>
                                 </MenuItem>
                                 <MenuItem key={2} value={'coach'}>
                                    <ListItemText>Coach</ListItemText>
                                 </MenuItem>
                                 <MenuItem key={3} value={'stand_in'}>
                                    <ListItemText>Stand In</ListItemText>
                                 </MenuItem>
                                 <MenuItem key={2} value={'manager'}>
                                    <ListItemText>Manager</ListItemText>
                                 </MenuItem>
                              </Select>
                           </Grid>
                           <Grid item xs={12}>
                              <TextField
                                 label="User's E-Mail"
                                 id="email"
                                 name="email"
                                 margin="dense"
                                 fullWidth
                                 required
                              />
                           </Grid>
                        </>
                     ) : (
                        <Grid mt={2} item xs={12}>
                           <Autocomplete
                              // key={key++}
                              label="Pick the Roles"
                              name="role"
                              multiple={true}
                              // required={required.planet}
                              options={autocompleteData}
                              getOptionValue={option => option.value}
                              getOptionLabel={option => option.label}
                              disableCloseOnSelect={true}
                              renderOption={(props, option, { selected }) =>
                                 option.inputValue ? (
                                    option.label
                                 ) : (
                                    <li {...props}>
                                       <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                       {option.label}
                                    </li>
                                 )
                              }
                              // helperText={helperText}
                              freeSolo={true}
                              onChange={(_event, newValue, reason, details) => {
                                 if (
                                    newValue &&
                                    reason === 'selectOption' &&
                                    details?.option.inputValue
                                 ) {
                                    // Create a new value from the user input
                                    autocompleteData.push({
                                       value: details?.option.inputValue,
                                       label: details?.option.inputValue,
                                    });
                                 }
                              }}
                              filterOptions={(options, params) => {
                                 const filtered = filter(options, params);

                                 // Suggest the creation of a new value
                                 if (params.inputValue !== '') {
                                    filtered.push({
                                       inputValue: params.inputValue,
                                       label: `Add "${params.inputValue}"`,
                                       value: params.inputValue,
                                    });
                                 }

                                 return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                           />
                        </Grid>
                     )}
                  </Grid>
               </form>
            )}
         />
      </>
   );
};

InviteMemForm.propTypes = {
   setIsOpen: PropTypes.func,
   mode: PropTypes.oneOf(['add', 'edit']),
};

export default InviteMemForm;
