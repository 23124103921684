import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Slide, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomAppbar from './CustomAppbar';

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="left" ref={ref} {...props} />;
});

const PopWindow = ({ isOpen, onClose, children, title, actions, noActionbar }) => {
   let isWantDark = useMediaQuery('(prefers-color-scheme: dark)');
   return (
      <Dialog
         PaperProps={{
            style: {
               backgroundColor: isWantDark ? '#000' : '#fff',
               color: isWantDark ? '#fff' : '#000',
            },
         }}
         fullScreen
         open={isOpen}
         onClose={onClose}
         TransitionComponent={Transition}
      >
         {!noActionbar && (
            <CustomAppbar key={12} color="inherit">
               <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                  <ArrowBackIosNewIcon />
               </IconButton>
               <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {title}
               </Typography>
               {actions}
            </CustomAppbar>
         )}
         {children}
      </Dialog>
   );
};

PopWindow.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   children: PropTypes.node.isRequired,
   title: PropTypes.string.isRequired,
   actions: PropTypes.node,
   noActionbar: PropTypes.bool,
};
PopWindow.defaultProps = {
   actions: null,
};

export default PopWindow;
