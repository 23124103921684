import request from '../utils/request';

export function doLogin(data) {
   return request({
      url: 'user/login',
      method: 'post',
      data,
   });
}

export function doSignUp(data) {
   return request({
      url: 'user/sign_up',
      method: 'post',
      data,
   });
}

export function updateUser(data) {
   return request({
      url: 'user/update',
      method: 'put',
      data,
   });
}
