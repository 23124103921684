import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

function ElevationScroll(props) {
   const { children, window } = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   const theme = useTheme();
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
   });

   return React.cloneElement(children, {
      elevation: trigger ? 2 : 0,
      backgroundColor: trigger ? theme.palette.background.paper : 'inherit',
   });
}

ElevationScroll.propTypes = {
   children: PropTypes.element.isRequired,
   /**
    * Injected by the documentation to work in an iframe.
    * You won't need it on your project.
    */
   window: PropTypes.func,
};

export default function CustomAppbar(props) {
   return (
      <React.Fragment>
         <ElevationScroll {...props}>
            <Box component={AppBar} width="100%" position={props.position} color="inherit">
               <Toolbar>{props.children}</Toolbar>
            </Box>
         </ElevationScroll>
         {!props.noToolbar && <Toolbar />}
      </React.Fragment>
   );
}

CustomAppbar.propTypes = {
   children: PropTypes.node.isRequired,
   position: PropTypes.string,
   noToolbar: PropTypes.bool
};

CustomAppbar.defaultProps = {
   position: 'sticky',
   noToolbar: false
};
