import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const CustomListItem = ({
   listAction,
   icon,
   title,
   onClick,
   rightIcon,
   secondaryTitle,
   disableRipple,
}) => {
   return (
      <ListItem disablePadding secondaryAction={rightIcon}>
         <ListItemButton onClick={onClick} disableRipple={disableRipple}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} secondary={secondaryTitle ? secondaryTitle : null} />
            {listAction ? listAction : null}
         </ListItemButton>
      </ListItem>
   );
};

CustomListItem.propTypes = {
   listAction: PropTypes.element,
   icon: PropTypes.element.isRequired,
   title: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   rightIcon: PropTypes.element,
   secondaryTitle: PropTypes.string,
    disableRipple: PropTypes.bool,
};

export default CustomListItem;
