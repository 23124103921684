import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
   Avatar,
   Badge,
   Button,
   CircularProgress,
   IconButton,
   LinearProgress,
   List,
   ListItemAvatar,
   ListItemButton,
   ListItemText,
   Menu,
   MenuItem,
} from '@mui/material';
import CustomAppbar from '../components/CustomAppbar';
import AddIcon from '@mui/icons-material/Add';
import { Fragment, useEffect, useState } from 'react';
import AddTeamForm from '../components/forms/AddTeamForm';
import CustomWarningDialog from '../components/CustomWarningDialog';
import CustomDialog from '../components/CustomDialog';
import { useInfiniteQuery } from 'react-query';
import { getTeams } from '../apis/teams';
import { ITEM_PER_PAGE } from '../config/constants';
import Splash from '../components/Splash';
import CustomError from '../components/CustomError';
import { useSearchParams } from 'react-router-dom';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PopWindow from '../components/PopWindow';
import Invites from './Teams/Invites';
import { useTeamInvites } from '../hooks/useTeamInvites';
import TeamProfile from './Teams/TeamProfile';

export default function Teams(props) {
   const [anchorEl, setAnchorEl] = useState(null);
   const [isOpen, setIsOpen] = useState(false);
   let [searchParams, setSearchParams] = useSearchParams();
   const [isWarningOpen, setIsWarningOpen] = useState(false);
   const [dialogInfo, setDialogInfo] = useState({
      title: '',
      content: '',
      actions: [],
   });
   const [isPopOpen, setIsPopOpen] = useState(false);

   let isView = searchParams.has('view');
   let isTeam = searchParams.has('team');
   useEffect(() => {
      if (isView) {
         setDialogInfo({
            title: 'Invitations',
            content: <Invites />,
         });
         setIsPopOpen(true);
      } else if (isTeam) {
         setDialogInfo({
            noActionbar: true,
            content: <TeamProfile />,
         });
         setIsPopOpen(true);
      } else {
         setIsPopOpen(false);
      }
   }, [isTeam, isView]);

   const handlePopClose = () => {
      setSearchParams();
      setIsPopOpen(false);
   };

   const fetchTeams = ({ pageParam = 1 }) =>
      getTeams({
         page: pageParam,
         items: ITEM_PER_PAGE,
      });

   const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } =
      useInfiniteQuery(['teams'], fetchTeams, {
         getNextPageParam: (lastPage, pages) => {
            if (lastPage.has_next_page) {
               return lastPage.next_page;
            }
            return undefined;
         },
      });

   const handleClose = () => setAnchorEl(null);

   //Query Logic
   const invitesQuery = useTeamInvites();
   // const { data, isLoading, isError } = citiesQuery;

   return status === 'loading' ? (
      <Splash />
   ) : status === 'error' ? (
      <CustomError error={error.message} />
   ) : (
      <>
         <CustomAppbar noToolbar key={11}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
               My Teams
            </Typography>
            <div>
               <IconButton
                  edge="end"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                     setDialogInfo({
                        title: `Add a Team`,
                        content: (
                           <AddTeamForm
                              setIsOpen={setIsOpen}
                              //   initVal={country}
                              //   Sub={Sub}
                              //   mode="edit"
                           />
                        ),
                        actions: (
                           <Button
                              type="submit"
                              variant="contained"
                              onClick={() =>
                                 document.getElementById('addTeamForm').dispatchEvent(
                                    new Event('submit', {
                                       cancelable: true,
                                       bubbles: true,
                                    })
                                 )
                              }
                           >
                              Confirm
                           </Button>
                        ),
                     });
                     setIsOpen(true);
                  }}
               >
                  <AddIcon color="inherit" />
               </IconButton>
               <IconButton
                  edge="end"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                     setSearchParams({ view: 'invites' });
                  }}
               >
                  <Badge
                     badgeContent={invitesQuery.data ? invitesQuery.data.length : null}
                     color="primary"
                  >
                     <GroupAddOutlinedIcon color="inherit" />
                  </Badge>
               </IconButton>
               {/* <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
               >
                  <MoreHorizIcon />
               </IconButton> */}
               <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
               >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
               </Menu>
            </div>
         </CustomAppbar>
         {status === 'loading' && <LinearProgress />}
         {isFetching && !isFetchingNextPage ? <LinearProgress /> : null}
         <Box>
            <List>
               {data.pages.map((group, i) => (
                  <Fragment key={i}>
                     {/* {console.log('awdawd',group.teams)} */}
                     {group.teams.map(team => (
                        <ListItemButton
                           key={team.slug}
                           onClick={() => {
                              setSearchParams({ team: team.slug });
                           }}
                        >
                           <ListItemAvatar>
                              <Avatar src="https://placeholder.co/500" />
                           </ListItemAvatar>
                           <ListItemText
                              primary={team.name}
                              //  secondary={secondary ? 'Secondary text' : null}
                           />
                        </ListItemButton>
                     ))}
                  </Fragment>
               ))}
            </List>
            <Box display="flex" justifyContent="center">
               {isFetchingNextPage ? (
                  <CircularProgress color="inherit" />
               ) : hasNextPage ? (
                  <Button variant="outlined" onClick={() => fetchNextPage()}>
                     Load More
                  </Button>
               ) : (
                  <Typography variant="overline" display="block" gutterBottom>
                     Nothing more to load
                  </Typography>
               )}
               {/* <button disabled={!hasNextPage || isFetchingNextPage}></button> */}
            </Box>
         </Box>
         <CustomDialog
            isOpen={isOpen}
            title={dialogInfo.title}
            onClose={() => setIsOpen(!isOpen)}
            actions={dialogInfo.actions}
            maxWidth="sm"
         >
            {dialogInfo.content}
         </CustomDialog>
         <CustomWarningDialog
            isOpen={isWarningOpen}
            title={dialogInfo.title}
            onClose={() => setIsWarningOpen(!isWarningOpen)}
            message={dialogInfo.message}
            actions={
               <>
                  <Button autoFocus onClick={() => setIsWarningOpen(false)}>
                     Cancel
                  </Button>
                  <Button
                     color="error"
                     variant="contained"
                     // onClick={handleDeleteGroup}
                     //  onClick={handleDelete}
                     autoFocus
                  >
                     Delete
                  </Button>
               </>
            }
         />
         <PopWindow
            isOpen={isPopOpen}
            noActionbar={dialogInfo.noActionbar}
            title={dialogInfo?.title}
            onClose={handlePopClose}
         >
            {dialogInfo?.content}
         </PopWindow>
      </>
   );
}
