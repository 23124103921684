import request from '../utils/request';

export function getTeams(params) {
   return request({
      url: 'user/team/',
      method: 'get',
      params,
   });
}

export function getTeamProfile(params) {
   return request({
      url: 'user/team/by_id',
      method: 'get',
      params,
   });
}

export function addTeam(data) {
   return request({
      url: 'user/team/create',
      method: 'post',
      data,
   });
}
export function editTeam(data) {
   return request({
      url: 'user/team/update',
      method: 'put',
      data,
   });
}

export function updateMemberRole(data) {
   return request({
      url: 'user/team/update_member_role',
      method: 'put',
      data,
   });
}
// invite
export function getTeamInvites(params) {
   return request({
      url: 'user/team/invites/user',
      method: 'get',
      params,
   });
}

export function respondInvite(data) {
   return request({
      url: 'user/team/invites/accept_or_reject',
      method: 'post',
      data,
   });
}

export function sendInvite(data) {
   return request({
      url: 'user/team/invite/create',
      method: 'post',
      data,
   });
}

export function revokeInvite(data) {
   return request({
      url: 'user/team/invites/revoke',
      method: 'post',
      data,
   });
}
