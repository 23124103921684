import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { APP_NAME } from '../config/constants';

export default function SEO({ description, title, siteTitle, isPrivate }) {
   return (
      <Helmet
         title={title}
         titleTemplate={siteTitle !== title ? `%s | ${siteTitle}` : null}
         meta={[
            {
               name: `description`,
               content: description,
            },
            {
               property: `og:title`,
               content: title,
            },
            {
               property: `og:description`,
               content: description,
            },
            {
               property: `og:type`,
               content: `website`,
            },
            {
               name: `twitter:card`,
               content: `summary`,
            },
            {
               name: `twitter:creator`,
               content: 'IYG',
            },
            {
               name: `twitter:title`,
               content: title,
            },
            {
               name: `twitter:description`,
               content: description,
            },
            {
               name: 'robots',
               content: isPrivate ? 'nofollow, noindex' : 'index, follow',
            },
         ]}
      />
   );
}

SEO.propTypes = {
   title: PropTypes.string.isRequired,
   siteTitle: PropTypes.string,
   description: PropTypes.string,
   isPrivate: PropTypes.bool,
};

SEO.defaultProps = {
   title: APP_NAME,
   siteTitle: 'Pro Tournaments, Made Easy!',
   description:
      'E-Warz is an Online Pro tournament hosting platform which provides fun new opportunities for both professional as well as for casual gamers.',
};
