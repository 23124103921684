import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const HeroCard = ({ icon, title, description }) => {
   return (
      <Box>
         <Box width="100%" display="flex" justifyContent="center">
            {icon}
         </Box>
         <Typography variant="h6" align="center">
            {title}
         </Typography>
         <Typography mt={1} align="center">
            {description}
         </Typography>
      </Box>
   );
};

HeroCard.propTypes = {
   icon: PropTypes.element.isRequired,
   title: PropTypes.string.isRequired,
   description: PropTypes.string.isRequired,
};

export default HeroCard;
