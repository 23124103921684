export var APP_VERSION = process.env.REACT_APP_VERSION;
export var APP_NAME = 'E-Warz Lite';
export var ORG_NAME = 'IYG Entertainment Pvt. Ltd.';
export var ORG_SITE = 'https://iyg.co.in';
export var AUTH_COOKIE = 'auth_cookie';
export var BASE_COOKIE = 'base_cookie';
export var ENTITY_DATA = 'entity_data';
export var APP_MODE = process.env.REACT_APP_NODE_ENV;
export var BASE_URL = process.env.REACT_APP_ENDPOINT_URL;
export var MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
export var DATA_URL = process.env.REACT_APP_BUCKET_URI;
export var CURRENCY = 'INR';
export var CURRENCY_SIGN = '₹';
// export var CURRENCY = 'AED';
// export var CURRENCY_SIGN = 'د.إ';
export var PHOLDER = 'https://placeholder.co/';
export var ITEM_PER_PAGE = 10;
export var SUPER_ADMIN = 'super_admin';
export var ADMIN = 'admin';
export var MERCHANT = 'merchant';
export var SUPERVISOR = 'supervisor';
export var DISTRIBUTOR = 'distributor';
export var SESSION_TIME = 30; // In Days
