import React from 'react';
import PropTypes from 'prop-types';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { Box, Typography } from '@mui/material';
const CustomError = ({ error }) => {
   return (
      <Box
         display="flex"
         flexDirection="column"
         alignItems="center"
         justifyContent="center"
         marginBlock="auto"
         className="noselect"
         justifyItems={'center'}
      >
         <SentimentDissatisfiedOutlinedIcon
            color="primary"
            sx={{ fontSize: 150, marginTop: '10%' }}
         />
         <Typography variant="h5"> {String(error.message).toUpperCase()}</Typography>
         <Typography variant="body1" color="textPrimary">
            {error.message === 'Network Error'
               ? 'Please Check your internet Connection.'
               : 'Something went wrong.'}
         </Typography>
      </Box>
   );
};

CustomError.propTypes = {
   error: PropTypes.object.isRequired,
};

export default CustomError;
