import React from 'react';
import PropTypes from 'prop-types';
import { makeValidateSync, Select, TextField } from 'mui-rff';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import {
   Alert,
   Grid,
   LinearProgress,
   ListItemText,
   MenuItem,
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import { getGamesList } from '../../apis/games';
import { addTeam, editTeam } from '../../apis/teams';

const addSchema = Yup.object().shape({
   game_slug: Yup.string().required(),
   name: Yup.string(null).required('Team Name is required'),
   facebook: Yup.string().url(),
   twitter: Yup.string().url(),
   youtube: Yup.string().url(),
});
const editSchema = Yup.object().shape({
   name: Yup.string().required(),
   facebook: Yup.string().url(),
   twitter: Yup.string().url(),
   youtube: Yup.string().url(),
});

const validateAdd = makeValidateSync(addSchema);
const validateEdit = makeValidateSync(editSchema);
// const required = makeRequired(schema);

const AddTeamForm = ({ Sub, setIsOpen, mode, initVal, stateCode }) => {
   //  console.log('re ren', setIsOpen);
   const queryClient = useQueryClient();
   const GamesListQuery = useQuery('games-list', () => getGamesList(), {
      enabled: mode !== 'edit',
      keepPreviousData: true,
   });
   const { data, isLoading, isError } = GamesListQuery;

   // console.log(initVal);
   const initialValues = {
      name: initVal ? initVal.team_name : null,
      ...initVal,
   };

   const handleSubmit = async values => {
      if (setIsOpen) {
         setIsOpen(false);
      }

      const { name, game_slug, facebook, twitter, youtube } = values;
      // console.log(values);

      if (mode === 'edit') {
         await editTeam({
            name: name,
            slug: initVal.team_slug,
            facebook: facebook,
            twitter: twitter,
            youtube: youtube,
         })
            .then(res => {
               //  console.log('done');
               queryClient.invalidateQueries(['team', initVal.team_slug]);
            })
            .catch(err => console.log(err));
      } else {
         await addTeam({
            name: name,
            game_slug: game_slug,
            facebook: facebook,
            twitter: twitter,
            youtube: youtube,
         })
            .then(res => {
               //  console.log('done');
               queryClient.invalidateQueries(['teams']);
            })
            .catch(err => console.log(err));
      }
   };

   if (isError) {
      return <Alert severity="error">{GamesListQuery.error}</Alert>;
   }
   console.log(data);
   return (
      <>
         {isLoading && <LinearProgress color="secondary" />}
         <Form
            validate={mode === 'edit' ? validateEdit : validateAdd}
            onSubmit={handleSubmit}
            validateOnBlur
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
               <form
                  style={{ marginTop: 10 }}
                  id="addTeamForm"
                  onSubmit={handleSubmit}
                  noValidate={true}
                  preventDefault={true}
               >
                  <Grid container spacing={1}>
                     <Grid mt={2} item xs={12}>
                        {mode !== 'edit' ? (
                           <Select
                              label="Choose Game"
                              id="GamesId"
                              name="game_slug"
                              autoFocus
                              required
                           >
                              <MenuItem value="" disabled={true}>
                                 Choose...
                              </MenuItem>
                              {data?.map(item => (
                                 <MenuItem key={item.id} value={item.slug}>
                                    {/* <ListItemIcon>
                                    <Image
                                       height={30}
                                       width={30}
                                       fit="fill"
                                       duration={0}
                                       src={'https://placehold.co/400'}
                                       />
                                 </ListItemIcon> */}
                                    <ListItemText>{item.name}</ListItemText>
                                 </MenuItem>
                              ))}
                           </Select>
                        ) : null}
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           label="Team Name"
                           id="name"
                           name="name"
                           margin="dense"
                           fullWidth
                           required
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField label="Facebook Link" name="facebook" margin="dense" fullWidth />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField label="Twitter Link" name="twitter" margin="dense" fullWidth />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField label="YouTube Link" name="youtube" margin="dense" fullWidth />
                     </Grid>
                  </Grid>
               </form>
            )}
         />
      </>
   );
};

AddTeamForm.propTypes = {
   setIsOpen: PropTypes.func,
   mode: PropTypes.oneOf(['add', 'edit']),
};

export default AddTeamForm;
