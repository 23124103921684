import PropTypes from 'prop-types';
import {
   BottomNavigation,
   BottomNavigationAction,
   Box,
   Paper,
   Toolbar,
   useMediaQuery,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SEO from './SEO';
import { ReactQueryDevtools } from 'react-query/devtools';

const Layout = () => {
   const [value, setValue] = useState(null);
   const ref = useRef(null);
   const theme = useTheme();
   const isPhone = useMediaQuery(theme.breakpoints.down('md'));
   let location = useLocation();
   useEffect(() => {
      switch (location.pathname) {
         case '/':
            setValue(0);
            break;
         case '/events':
            setValue(1);
            break;
         case '/teams':
            setValue(2);
            break;
         case '/account':
            setValue(3);
            break;
         default:
            break;
      }
   }, [location.pathname]);
   // console.log(theme.palette);
   return (
      <>
         <Box sx={{ pb: 1 }} ref={ref}>
            <SEO />
            <Box sx={{ marginLeft: isPhone ? 0 : 10, minHeight: 'calc(100vh - 1rem)' }}>
               <Outlet />
            </Box>
            <ReactQueryDevtools /* position='bottom-right' */ initialIsOpen={false} />
            <Toolbar />
            <Paper
               sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  top: isPhone ? 'inherit' : 0,
                  right: isPhone ? 0 : 'inherit',
                  borderTopLeftRadius: isPhone ? null : 0,
                  borderBottomRightRadius: isPhone ? 0 : null,
                  borderBottomLeftRadius: 0,
                  backgroundImage: 'inherit',
               }}
               backgroundColor={theme.palette.background.default}
               elevation={3}
            >
               <BottomNavigation
                  sx={{ display: isPhone ? 'flex' : 'grid', backgroundColor: 'rgba(0,0,0,0)' }}
                  showLabels={isPhone ? false : true}
                  value={value}
                  onChange={(event, newValue) => {
                     setValue(newValue);
                  }}
               >
                  <CustomLink to="/" label="Home" icon={<HomeIcon />} />
                  <CustomLink to="/events" label="Events" icon={<SportsEsportsIcon />} />
                  <CustomLink to="/teams" label="Teams" icon={<GroupsIcon />} />
                  <CustomLink to="/account" label="Account" icon={<AccountCircleIcon />} />
               </BottomNavigation>
            </Paper>
         </Box>
      </>
   );
};
function CustomLink({ children, to, ...props }) {
   return (
      <BottomNavigationAction
         LinkComponent={Link}
         // style={{ textDecoration: match ? "underline" : "none" }}
         to={to}
         {...props}
      >
         {children}
      </BottomNavigationAction>
   );
}
Layout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default Layout;
