import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Link as MatLink } from '@mui/material';
import { ORG_NAME, ORG_SITE } from '../config/constants';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { makeRequired } from 'mui-rff';
import { Checkboxes } from 'mui-rff';
import { doLogin } from '../apis/auth';
import { Link, useNavigate } from 'react-router-dom';
import { resetAuth, setAuth, validateAuth } from '../utils/CookieUtil';

function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <MatLink color="inherit" target="_blank" href={ORG_SITE}>
            {ORG_NAME}
         </MatLink>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   );
}

const schema = Yup.object({
   email: Yup.string().email().required(),
   password: Yup.string().required().min(6),
});

const validate = makeValidate(schema);
const required = makeRequired(schema);
const initialValues = {
   email: '',
   password: '',
   isRemember: true,
};

export default function LoginPage() {
   let navigate = useNavigate();
   const handleLogin = async values => {
      await doLogin({
         email: values.email,
         password: values.password,
      })
         .then(res => {
            const auth = setAuth(res, values.isRemember);
            if (validateAuth(auth)) {
               navigate('/account', { replace: true });
            } else {
               console.log('validation failed');
               resetAuth();
            }
         })
         .catch(err => console.log(err));
   };

   return (
      <Grid container component="main" sx={{ height: '100vh' }}>
         <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
               backgroundImage: 'url(https://source.unsplash.com/random)',
               backgroundRepeat: 'no-repeat',
               backgroundColor: t =>
                  t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
               backgroundSize: 'cover',
               backgroundPosition: 'center',
            }}
         />
         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
               sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}
            >
               <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">
                  Welcome Back 😊
               </Typography>
               <Box>
                  <Form
                     validate={validate}
                     onSubmit={handleLogin}
                     sx={{ mt: 1 }}
                     initialValues={initialValues}
                     render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} noValidate={true}>
                           <TextField
                              margin="normal"
                              fullWidth
                              id="email"
                              label="Email Address"
                              name="email"
                              autoComplete="email"
                              autoFocus
                              required={required.hidden}
                           />
                           <TextField
                              margin="normal"
                              required={required.hidden}
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                           />
                           <Checkboxes
                              name="isRemember"
                              data={{
                                 label: 'Remember me',
                                 value: true,
                              }}
                           />
                           <Button
                              type="submit"
                              disabled={submitting}
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                           >
                              Sign In
                           </Button>
                        </form>
                     )}
                  />
                  <Grid container>
                     <Grid item xs>
                        <MatLink href="#" variant="body2">
                           Forgot password?
                        </MatLink>
                     </Grid>
                     <Grid item>
                        <MatLink to="/signup" component={Link} variant="body2">
                           {"Don't have an account? Sign Up"}
                        </MatLink>
                     </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5 }} />
               </Box>
            </Box>
         </Grid>
      </Grid>
   );
}
