import { Box, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Image } from 'mui-image';
import EWarzLogo from '../../assets/eWarz-trans-dbg.png';
import EWarzLogolight from '../../assets/eWarz-trans-lbg.png';
import { APP_VERSION, ORG_NAME } from '../../config/constants';
const About = () => {
   const theme = useTheme();
   let isWantDark = useMediaQuery('(prefers-color-scheme: dark)');
   const isPhone = useMediaQuery(theme.breakpoints.down('md'));
   return (
      <Box>
         <Grid container spacing={2} alignContent="center">
            <Grid
               container
               direction="row"
               item
               xs={12}
               justifyContent="center"
               alignContent="center"
            >
               <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Image
                     src={isWantDark ? EWarzLogo : EWarzLogolight}
                     width={isPhone ? '40vw' : '20vw'}
                     height="100%"
                     sx={{ mt: 3 }}
                     fit="contain"
                  />
                  <Typography
                     variant="body1"
                     align="center"
                     sx={{ width: '100%', mt: '0px !important', pb: 2.5 }}
                  >
                     by
                     <br />
                     {ORG_NAME}
                  </Typography>
               </Stack>
            </Grid>
            <Grid item xs={12}>
               <Divider />
               <Box
                  //   width={isPhone ? '90%' : '70%'}
                  p={4}
                  display="flex"
                  alignContent="center"
                  justifyContent="center"
               >
                  <Typography align="left" gutterBottom>
                     E-Warz is a tournament application for Mobile, PC and Console Gamers.
                  </Typography>
               </Box>
               <Divider />
               <Box display="flex" alignContent="center" justifyContent="center">
                  <Typography variant="subtitle1" mt={2}>
                     &copy; {ORG_NAME}
                  </Typography>
               </Box>
               <Box display="flex" justifyContent="center" flexDirection="column">
                  <Typography alignSelf="center" mt={5} variant="caption">
                     Developed by ITESoftcare
                  </Typography>
                  <Typography alignSelf="center" mt={1} variant="caption">
                     App version {APP_VERSION}
                  </Typography>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};

export default About;
