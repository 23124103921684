import {
   Box,
   Button,
   Card,
   CardContent,
   Container,
   Divider,
   Grid,
   Stack,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import React from 'react';
import Image from 'mui-image';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SEO from '../components/SEO';
import EWarzLogo from '../assets/eWarz-trans-dbg.png';
import EWarzLogolight from '../assets/eWarz-trans-lbg.png';
import GroupIcon from '@mui/icons-material/Group';
import HeroCard from '../components/HeroCard';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const Home = () => {
   const theme = useTheme();
   let isWantDark = useMediaQuery('(prefers-color-scheme: dark)');
   const isPhone = useMediaQuery(theme.breakpoints.down('md'));
   return (
      <Box>
         <SEO title="Home" />
         <Container maxWidth="xl">
            <Grid container spacing={2} alignContent="center">
               <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  justifyContent="center"
                  alignContent="center"
               >
                  <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                     <Image
                        src={isWantDark ? EWarzLogo : EWarzLogolight}
                        width={isPhone ? '40vw' : '20vw'}
                        height="100%"
                        sx={{ mt: 3 }}
                        fit="contain"
                        easing="ease-in-out"
                     />
                     <Typography
                        variant="body1"
                        align="center"
                        sx={{ width: '100%', mt: '10px !important', pb: 2.5 }}
                     >
                        Pro Tournaments, Made easy.
                     </Typography>
                  </Stack>
               </Grid>
               <Grid item xs={12}>
                  <Card>
                     <CardContent>
                        <Box
                           width="100%"
                           display="flex"
                           alignContent="center"
                           justifyContent="center"
                        >
                           <Box
                              width={isPhone ? '90%' : '70%'}
                              display="flex"
                              alignContent="center"
                              justifyContent="center"
                           >
                              <Typography align="left" gutterBottom>
                                 E-Warz is an online gaming platform, where we host numerous
                                 tournaments for various competitive games. We serve as a medium for
                                 individuals who wish to host tournament events and lack the
                                 framework to do so.
                              </Typography>
                           </Box>
                        </Box>
                        <Box
                           width="100%"
                           display="flex"
                           mt={1}
                           alignContent="center"
                           justifyContent="center"
                        >
                           <Button
                              startIcon={<OpenInNewIcon />}
                              href="https://ewarz.org"
                              target="_blank"
                              size="medium"
                              variant="contained"
                           >
                              Learn More
                           </Button>
                        </Box>
                     </CardContent>
                  </Card>
               </Grid>
               <Grid item xs={12}>
                  <Card>
                     <CardContent>
                        <Typography variant="h5" align="center">
                           How it Works?
                        </Typography>
                        <Divider sx={{ my: 2 }} variant="middle" />
                        <Grid container spacing={2}>
                           <Grid item xs={12} sm={6} lg={4}>
                              <HeroCard
                                 icon={<GroupIcon sx={{ fontSize: 50 }} />}
                                 title="Join/Make a Team"
                                 description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda, quo. Dolore debitis
            facere amet nobis minus non hic libero eum vel nesciunt. Quod adipisci quis debitis,
            veniam recusandae voluptates reiciendis!"
                              />
                              <Divider sx={{ my: 2 }} variant="middle" />
                           </Grid>
                           <Grid item xs={12} sm={6} lg={4}>
                              <HeroCard
                                 icon={<AppRegistrationIcon sx={{ fontSize: 50 }} />}
                                 title="Register for a Tournament"
                                 description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda, quo. Dolore debitis
            facere amet nobis minus non hic libero eum vel nesciunt. Quod adipisci quis debitis,
            veniam recusandae voluptates reiciendis!"
                              />
                              <Divider sx={{ my: 2 }} variant="middle" />
                           </Grid>
                           <Grid item xs={12} sm={12} md={12} lg={4}>
                              <HeroCard
                                 icon={<SportsEsportsIcon sx={{ fontSize: 50 }} />}
                                 title="Play & Win"
                                 description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda, quo. Dolore debitis
            facere amet nobis minus non hic libero eum vel nesciunt. Quod adipisci quis debitis,
            veniam recusandae voluptates reiciendis!"
                              />
                              <Divider sx={{ my: 2 }} variant="middle" />
                           </Grid>
                        </Grid>
                     </CardContent>
                  </Card>
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
};

export default Home;
