import {
   Button,
   createTheme,
   CssBaseline,
   IconButton,
   Snackbar,
   ThemeProvider,
   useMediaQuery,
} from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import Splash from './components/Splash';
import { getTheme } from './utils/CookieUtil';
import routes from './utils/routes';
import CloseIcon from '@mui/icons-material/Close';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Grow from '@mui/material/Grow';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const getDesignTokens = mode => ({
   palette: {
      mode,
      primary: {
         main: '#af2d2d',
      },
      secondary: {
         main: '#17a2b8',
      },
      background: {
         ...(mode === 'dark'
            ? {
                 default: '#131313',
                 paper: '#272727',
              }
            : {
                 default: '#f2f2f2',
                 paper: '#fff',
              }),
      },
      text: {
         ...(mode === 'dark'
            ? {
                 primary: '#ffffff',
              }
            : {
                 primary: '#000000',
              }),
      },
   },
   props: {
      MuiAppBar: {
         color: 'default',
      },
   },
   shape: {
      borderRadius: 9,
   },
});

function App() {
   let isWantDark = useMediaQuery('(prefers-color-scheme: dark)');
   const the = getTheme();
   const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
   const [waitingWorker, setWaitingWorker] = useState(null);

   let themePref = the === undefined ? (isWantDark ? 'dark' : 'light') : 'dark';
   let element = useRoutes(routes);
   const darkModeTheme = createTheme(getDesignTokens(themePref));

   const reloadPage = () => {
      waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
      setIsUpdateAvailable(false);
      window.location.reload(true);
   };

   // const time = useRef(Date.now()); //can be let, depending of your logic

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const onSWUpdate = registration => {
      // if (Date.now() - time.current <= 2000) {
      //   return reloadPage()
      // }
      setIsUpdateAvailable(true);
      console.log('reggg', registration);
      setWaitingWorker(registration.waiting);
   };

   useEffect(() => {
      serviceWorkerRegistration.register({
         onSuccess(registration) {
            console.debug('serviceWorkerRegistration success');
         },
         onUpdate: onSWUpdate,
      });
   }, [onSWUpdate]);

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setIsUpdateAvailable(false);
   };

   return (
      <ThemeProvider theme={darkModeTheme}>
         <CssBaseline />
         <QueryClientProvider client={queryClient}>
            <Suspense fallback={<Splash />}>{element}</Suspense>
         </QueryClientProvider>
         <Toaster
            // containerStyle={{ bottom: 65 }}
            position="top-center"
            // gutter={8}
            toastOptions={{
               // Define default options
               duration: 5000,
               style: {
                  background: '#363636',
                  color: '#fff',
               },
               // Default options for specific types
               success: {
                  duration: 2000,
                  theme: {
                     primary: '#DF4C23',
                     secondary: '#22333B',
                  },
               },
            }}
         />
         <Snackbar
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            open={isUpdateAvailable}
            onClose={handleClose}
            TransitionComponent={Grow}
            sx={{ bottom: { xs: 65, md: 15 } }}
            message="Update Available!"
            action={
               <>
                  <Button color="primary" size="medium" onClick={reloadPage}>
                     Refresh Now
                  </Button>
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     sx={{ p: 0.5 }}
                     onClick={() => setIsUpdateAvailable(false)}
                  >
                     <CloseIcon />
                  </IconButton>
               </>
            }
            key={342}
         />
      </ThemeProvider>
   );
}

export default App;
