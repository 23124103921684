import React from 'react';

const Event = () => {
    return (
        <div>
           The event 
        </div>
    );
};

export default Event;