import {
   AppBar,
   Avatar,
   Button,
   Card,
   CardContent,
   Chip,
   Grid,
   IconButton,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   ListSubheader,
   Table,
   TableBody,
   TableCell,
   TableRow,
   Toolbar,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import { Image } from 'mui-image';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Splash from '../../components/Splash';
import CustomError from '../../components/CustomError';
import { getTeamProfile, revokeInvite } from '../../apis/teams';
import SEO from '../../components/SEO';
import { Container } from '@mui/system';
import { toFirstCap } from '../../utils/misc';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import CustomDialog from '../../components/CustomDialog';
import { useState } from 'react';
import AddTeamForm from '../../components/forms/AddTeamForm';
import InviteMemForm from '../../components/forms/InviteMemForm';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
const TeamProfile = () => {
   const [isOpen, setIsOpen] = useState(false);
   const [dialogInfo, setDialogInfo] = useState({
      title: '',
      content: '',
      actions: [],
   });
   // eslint-disable-next-line no-unused-vars
   let [searchParams, setSearchParams] = useSearchParams();
   const navigate = useNavigate();
   const theme = useTheme();
   const isPhone = useMediaQuery(theme.breakpoints.down('md'));
   let teamSlug = searchParams.get('team');
   // Query Logic
   const teamQuery = useQuery(
      ['team', teamSlug],
      () =>
         getTeamProfile({
            slug: teamSlug,
         }),
      {
         enabled: !!teamSlug,
         keepPreviousData: true,
      }
   );

   const { data, isLoading, isError, error } = teamQuery;
   const queryClient = useQueryClient();
   const revokeInviteMut = useMutation(data => revokeInvite(data), {
      onSuccess: () => {
         queryClient.invalidateQueries(['team', teamSlug]);
      },
   });
   if (isLoading) {
      return <Splash />;
   }

   if (isError) {
      return <CustomError error={error} />;
   }
   const {
      team_name,
      // team_image,
      // team_slug,
      team_facebook,
      team_twitter,
      team_youtube,
      is_manager,
      invitations,
      game_details,
      team_members,
   } = data;
   return (
      <>
         <AppBar elevation={1}>
            <Toolbar>
               <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => navigate(-1)}
                  aria-label="close"
               >
                  <ArrowBackIosNewIcon />
               </IconButton>
               <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {/* {title} */}
                  {team_name}
               </Typography>
               {/* {actions} */}
               {is_manager ? (
                  <IconButton
                     sx={{ mr: 1 }}
                     onClick={() => {
                        setDialogInfo({
                           title: `Edit ${team_name}`,
                           content: (
                              <AddTeamForm
                                 mode="edit"
                                 setIsOpen={setIsOpen}
                                 game_slug={game_details.game_slug}
                                 initVal={data}
                              />
                           ),
                           actions: (
                              <Button
                                 type="submit"
                                 variant="contained"
                                 onClick={() =>
                                    document.getElementById('addTeamForm').dispatchEvent(
                                       new Event('submit', {
                                          cancelable: true,
                                          bubbles: true,
                                       })
                                    )
                                 }
                              >
                                 Confirm
                              </Button>
                           ),
                        });
                        setIsOpen(true);
                     }}
                  >
                     <EditOutlinedIcon color="inherit" />
                  </IconButton>
               ) : null}
            </Toolbar>
         </AppBar>
         <Toolbar />

         <Container>
            <Grid
               container
               //  alignItems="stretch"
               direction={isPhone ? 'row' : 'row-reverse'}
               spacing={2}
               mt={2}
            >
               <Grid container item justifyContent={'center'} xs={12} md={4} lg={3}>
                  <Image
                     sx={{ borderRadius: theme.shape.borderRadius - 7 }}
                     src={'https://placeholder.pics/svg/500'}
                     duration={500}
                     height={300}
                     width={300}
                  />
               </Grid>
               <Grid item xs={12} md={8} lg={9}>
                  <Card sx={{ height: '100%' }}>
                     <CardContent>
                        <Table size="small">
                           <TableBody>
                              <TableRow>
                                 <TableCell align="left" component="th" scope="row">
                                    <Typography color="textSecondary" variant="body1">
                                       Team Name:
                                    </Typography>
                                 </TableCell>
                                 <TableCell align="left">
                                    <Typography color="textPrimary" variant="h6">
                                       {team_name}
                                    </Typography>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="left" component="th" scope="row">
                                    <Typography color="textSecondary" variant="body1">
                                       Facebook:
                                    </Typography>
                                 </TableCell>
                                 <TableCell align="left">
                                    <Typography color="textPrimary" variant="h6">
                                       {team_facebook}
                                    </Typography>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="left" component="th" scope="row">
                                    <Typography color="textSecondary" variant="body1">
                                       Twitter:
                                    </Typography>
                                 </TableCell>
                                 <TableCell align="left">
                                    <Typography color="textPrimary" variant="h6">
                                       {team_twitter}
                                    </Typography>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="left" component="th" scope="row">
                                    <Typography color="textSecondary" variant="body1">
                                       Youtube:
                                    </Typography>
                                 </TableCell>
                                 <TableCell align="left">
                                    <Typography color="textPrimary" variant="h6">
                                       {team_youtube}
                                    </Typography>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="left" component="th" scope="row">
                                    <Typography color="textSecondary" variant="body1">
                                       Game:
                                    </Typography>
                                 </TableCell>
                                 <TableCell align="left">
                                    <Typography color="textPrimary" variant="h6">
                                       {game_details.name}
                                    </Typography>
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </CardContent>
                  </Card>
               </Grid>
               <Grid item xs={12} md={6} lg={4}>
                  <Card>
                     <List disablePadding>
                        <ListSubheader>Members</ListSubheader>
                        {team_members.map(member => (
                           <ListItem
                              key={member.team_member_id}
                              secondaryAction={
                                 <IconButton
                                    sx={{ display: is_manager ? 'inherit' : 'none' }}
                                    onClick={() => {
                                       setDialogInfo({
                                          title: `Manage ${member.name}`,
                                          content: (
                                             <InviteMemForm
                                                mode="edit"
                                                setIsOpen={setIsOpen}
                                                data={member}
                                                initVal={data}
                                             />
                                          ),
                                          actions: (
                                             <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={() =>
                                                   document
                                                      .getElementById('invitationForm')
                                                      .dispatchEvent(
                                                         new Event('submit', {
                                                            cancelable: true,
                                                            bubbles: true,
                                                         })
                                                      )
                                                }
                                             >
                                                Confirm
                                             </Button>
                                          ),
                                       });
                                       setIsOpen(true);
                                    }}
                                 >
                                    <EditOutlinedIcon color="inherit" />
                                 </IconButton>
                              }
                           >
                              <ListItemAvatar>
                                 <Avatar src={member.image} />
                              </ListItemAvatar>
                              <ListItemText
                                 primary={member.name}
                                 secondary={member.role.map((role, i) => (
                                    <Chip
                                       // icon={icon}
                                       size="small"
                                       color={
                                          role === 'manager'
                                             ? 'primary'
                                             : role === 'captain'
                                             ? 'secondary'
                                             : role === 'coach'
                                             ? 'warning'
                                             : role === 'player'
                                             ? 'error'
                                             : 'default'
                                       }
                                       label={toFirstCap(role)}
                                       sx={{ marginInline: 0.5 }}
                                       //   onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                    />
                                 ))}
                              />
                           </ListItem>
                        ))}
                        {invitations.length > 0 && <ListSubheader>Pending Members</ListSubheader>}
                        {invitations.map(member => (
                           <ListItem
                              key={member.team_member_id}
                              secondaryAction={
                                 <IconButton
                                    sx={{ display: is_manager ? 'inherit' : 'none' }}
                                    onClick={() =>
                                       revokeInviteMut.mutate({
                                          token: member.token,
                                       })
                                    }
                                 >
                                    <DeleteForeverOutlinedIcon color="error" />
                                 </IconButton>
                              }
                           >
                              <ListItemAvatar>
                                 <Avatar src={member.image} />
                              </ListItemAvatar>
                              <ListItemText
                                 primary={member.player_email}
                                 secondary={
                                    <Chip
                                       // icon={icon}
                                       disabled
                                       size="small"
                                       color={
                                          member.invite_role === 'manager'
                                             ? 'primary'
                                             : member.invite_role === 'captain'
                                             ? 'secondary'
                                             : member.invite_role === 'coach'
                                             ? 'warning'
                                             : member.invite_role === 'player'
                                             ? 'error'
                                             : 'default'
                                       }
                                       label={toFirstCap(member.invite_role)}
                                       sx={{ marginInline: 0.5 }}
                                       //   onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                    />
                                 }
                              />
                           </ListItem>
                        ))}
                        {is_manager ? (
                           <ListItem>
                              <Button
                                 sx={{ mx: 2 }}
                                 onClick={() => {
                                    setDialogInfo({
                                       title: `Invite to ${team_name}`,
                                       content: (
                                          <InviteMemForm
                                             setIsOpen={setIsOpen}
                                             game_slug={game_details.game_slug}
                                             initVal={data}
                                          />
                                       ),
                                       actions: (
                                          <Button
                                             type="submit"
                                             variant="contained"
                                             onClick={() =>
                                                document
                                                   .getElementById('invitationForm')
                                                   .dispatchEvent(
                                                      new Event('submit', {
                                                         cancelable: true,
                                                         bubbles: true,
                                                      })
                                                   )
                                             }
                                          >
                                             Confirm
                                          </Button>
                                       ),
                                    });
                                    setIsOpen(true);
                                 }}
                                 startIcon={<PersonAddAlt1OutlinedIcon color="inherit" />}
                                 variant="contained"
                                 fullWidth
                              >
                                 invite
                              </Button>
                           </ListItem>
                        ) : null}
                     </List>
                  </Card>
               </Grid>
               <Grid item xs={8}>
                  adwwad
                  {/* <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                     >
                        <Typography>Gym Images</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Empty message="No Gym Images" />
                     </AccordionDetails>
                  </Accordion> */}
               </Grid>
            </Grid>
         </Container>
         <SEO title={team_name} />
         <CustomDialog
            isOpen={isOpen}
            title={dialogInfo.title}
            onClose={() => setIsOpen(!isOpen)}
            actions={dialogInfo.actions}
            maxWidth="sm"
         >
            {dialogInfo.content}
         </CustomDialog>
      </>
   );
};

export default TeamProfile;
