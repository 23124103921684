import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grow, IconButton } from '@mui/material';
import { forwardRef } from 'react';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { APP_NAME } from '../config/constants';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
   backgroundColor: theme.palette.primary.main,
   color: theme.palette.primary.contrastText,
}));

const Transition = forwardRef(function Transition(props, ref) {
   return <Grow direction="up" ref={ref} {...props} />;
});
export default function CustomDialog(
   { isOpen, actions, children, title, onClose, maxWidth },
   props
) {
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

   return (
      <Dialog
         fullScreen={fullScreen}
         open={isOpen}
         onClose={onClose}
         scroll="paper"
         maxWidth={maxWidth}
         {...props}
         TransitionComponent={Transition}
         aria-labelledby={title}
      >
         <StyledDialogTitle>
            {title}
            {onClose ? (
               <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                     position: 'absolute',
                     right: 8,
                     top: 8,
                     color: 'inherit',
                  }}
               >
                  <CloseIcon />
               </IconButton>
            ) : null}
         </StyledDialogTitle>
         <DialogContent>{children}</DialogContent>
         <DialogActions>{actions}</DialogActions>
      </Dialog>
   );
}

CustomDialog.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   onClose: PropTypes.func.isRequired,
   children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
   actions: PropTypes.node,
};

CustomDialog.defaultProps = {
   title: APP_NAME,
   maxWidth: 'md',
};
