import {
   AppBar,
   Avatar,
   Box,
   Button,
   ButtonBase,
   Divider,
   Drawer,
   Grid,
   IconButton,
   List,
   ListItemText,
   ListSubheader,
   Stack,
   Switch,
   Toolbar,
   Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@emotion/react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from '@mui/icons-material/Info';
import CustomListItem from '../../components/CustomListItem';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useEffect, useState } from 'react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import PopWindow from '../../components/PopWindow';
import About from './About';
import { getAuth, resetAuth } from '../../utils/CookieUtil';
import { Link, useSearchParams } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { DATA_URL } from '../../config/constants';
const Account = () => {
   const theme = useTheme();
   let [searchParams, setSearchParams] = useSearchParams();
   const [isSettingsOpen, setIsSettingsOpen] = useState(false);
   const [dialogInfo, setDialogInfo] = useState({});
   const [isPopOpen, setIsPopOpen] = useState(false);
   const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen);

   const { name, type, pic } = getAuth();
   let pageName = searchParams.get('page');
   console.log(pageName);

   useEffect(() => {
      if (pageName === 'about') {
         setDialogInfo({
            title: 'About',
            content: <About />,
         });
         setIsPopOpen(true);
      } else {
         setIsPopOpen(false);
      }
   }, [pageName]);

   const handlePopClose = () => {
      setSearchParams();
      setIsPopOpen(false);
   };
   return (
      <Box>
         <AppBar position="relative" variant="outlined" sx={{ border: 0 }} color="transparent">
            <Toolbar>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Account
               </Typography>
               <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleSettings}
                  //   sx={{ mr: 2 }}
               >
                  <SettingsIcon />
               </IconButton>
            </Toolbar>
            {type === 'user' ? (
               <Box
                  p={2}
                  component={ButtonBase}
                  alignContent="center"
                  justifyContent={'center'}
                  display="flex"
               >
                  {console.log(pic)}
                  <Avatar
                     alt={name}
                     src={DATA_URL + pic}
                     sx={{
                        width: 56,
                        height: 56,
                        mr: 2,
                        bgcolor: theme.palette.primary.main,
                        color: 'white',
                     }}
                  />
                  <Stack direction="row" spacing={2}>
                     <ListItemText
                        primary={name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary="Free Member"
                        secondaryTypographyProps={{ variant: 'body1', align: 'left' }}
                     />
                     <ArrowForwardIosIcon sx={{ alignSelf: 'center' }} />
                  </Stack>
               </Box>
            ) : (
               <Box
                  p={2}
                  // component={ButtonBase}
                  alignContent="center"
                  justifyContent={'center'}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
               >
                  <Typography alignItems="center" color="secondary" variant="h4">
                     Dude!
                  </Typography>
                  <Typography alignItems="center" variant="h5">
                     haven't logged in yet? 😩
                  </Typography>
                  <Typography variant="body2" mt={1} mb={3}>
                     (Now it's good time to do so.)
                  </Typography>
                  <Grid container spacing={1} mb={2}>
                     <Grid item xs={null} sm></Grid>
                     <Grid container item justifyContent={'center'} xs={12} sm={10} md={8} lg={6}>
                        <Button
                           startIcon={<LoginIcon />}
                           component={Link}
                           to="/login"
                           size="large"
                           fullWidth
                           variant="contained"
                        >
                           Log In
                        </Button>
                        <Box m={1}>or</Box>
                        <Button
                           component={Link}
                           startIcon={<AppRegistrationIcon />}
                           to="/signup"
                           size="large"
                           fullWidth
                           variant="outlined"
                        >
                           Register
                        </Button>
                     </Grid>
                     <Grid item xs={null} sm></Grid>
                  </Grid>
                  {/* <Stack direction="row" spacing={2}> */}

                  {/* </Stack> */}
               </Box>
            )}
         </AppBar>
         <Divider />
         <Grid container spacing={1}>
            <Grid item xs={null} sm></Grid>
            <Grid item xs={12} sm={10} md={8} lg={6}>
               <List component="nav">
                  {type && (
                     <>
                        <CustomListItem
                           title="My Wallet"
                           icon={<AccountBalanceWalletIcon />}
                           rightIcon={<ArrowForwardIosIcon sx={{ alignSelf: 'center' }} />}
                           onClick={() => null}
                        />
                        <CustomListItem
                           title="My Favourite Games"
                           icon={<FavoriteIcon />}
                           rightIcon={<ArrowForwardIosIcon sx={{ alignSelf: 'center' }} />}
                           onClick={() => null}
                        />
                        <CustomListItem
                           title="My Achievements"
                           icon={<EmojiEventsIcon />}
                           rightIcon={<ArrowForwardIosIcon sx={{ alignSelf: 'center' }} />}
                           onClick={() => null}
                        />
                        <CustomListItem
                           title="My Gamer ID's"
                           icon={<AlternateEmailIcon />}
                           rightIcon={<ArrowForwardIosIcon sx={{ alignSelf: 'center' }} />}
                           onClick={() => null}
                        />
                     </>
                  )}
                  <CustomListItem
                     title="About"
                     icon={<InfoIcon />}
                     rightIcon={<ArrowForwardIosIcon sx={{ alignSelf: 'center' }} />}
                     onClick={() => {
                        setSearchParams({ page: 'about' });
                        // setDialogInfo({
                        //    title: 'About',
                        //    content: <About />,
                        // });
                        // setIsPopOpen(true);
                     }}
                  />
               </List>
            </Grid>
            <Grid item xs={null} sm></Grid>
         </Grid>
         {/* <CustomDialog noTitle isOpen={true}>
             awdawdwa
          </CustomDialog> */}
         <Drawer anchor="right" open={isSettingsOpen} onClose={toggleSettings}>
            <Box sx={{ height: '100%', bgcolor: 'background.paper' }}>
               <List
                  sx={{ bgcolor: 'background.paper' }}
                  subheader={<ListSubheader>Settings</ListSubheader>}
               >
                  <CustomListItem
                     title="App Theme"
                     icon={<DarkModeIcon />}
                     disableRipple
                     secondaryTitle="Following system theme"
                     listAction={
                        <Switch
                           edge="end"
                           disabled
                           sx={{ ml: 2 }}
                           //   onChange={handleToggle('wifi')}
                           //   checked={checked.indexOf('wifi') !== -1}
                           inputProps={{
                              'aria-labelledby': 'switch-list-label-wifi',
                           }}
                        />
                     }
                  />
                  {type && (
                     <CustomListItem
                        title="Log Out"
                        icon={<LogoutIcon color="error" />}
                        onClick={() => {
                           resetAuth();
                           toggleSettings();
                           // router.push('/');
                        }}
                        secondaryTitle="This will log you out from the App"
                     />
                  )}
               </List>
            </Box>
         </Drawer>
         <PopWindow isOpen={isPopOpen} title={dialogInfo?.title} onClose={handlePopClose}>
            {dialogInfo?.content}
         </PopWindow>
      </Box>
   );
};

export default Account;
