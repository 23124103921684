import React from 'react';
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import { Box, Typography } from '@mui/material';
const Empty = ({ message }) => {
   return (
      <div style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
         <Box marginBlock={5}>
            <SentimentVeryDissatisfiedOutlinedIcon sx={{ fontSize: 100 }} />
            <Typography sx={{ marginTop: 1 }} variant="subtitle1" color="textPrimary">
               {message}
            </Typography>
         </Box>
      </div>
   );
};

Empty.propTypes = {
   message: PropTypes.string, // should be string
};

Empty.defaultProps = {
   message: 'No Data',
};
export default Empty;
